import { Link } from 'gatsby'
import React from 'react'
import * as styles from './countrySelector.module.css'
import { PropTypes } from 'prop-types'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import Icon from '../icons'


function CountrySelector() {

  return (
    <div className={styles.root} >
      <div className={styles.item} >
        <div className={styles.titleItem} >
          <h3 className={styles.title}>Are you located in any of the countries below?</h3>
        </div>
        <div className={styles.excerpt}>
          <p>Enter website for</p>
        </div>

        <div className={styles.button}>
            <Icon symbol='noIcon'  />
          <a className={styles.link} href='https://dignio.com/no/'>Norway  →</a>
        </div>
        <div className={styles.button}>
          <Icon symbol='seIcon' />
          <a className={styles.link} href='https://dignio.com/se/'>Sweden  →</a>
        </div>
        <div className={styles.button}>
          <Icon symbol='usIcon' />
          <a className={styles.link} href='https://dignio.com/us/'>United States  →</a>
        </div>
      </div>

    </div>
  )
}


export default CountrySelector
