import imageUrlBuilder from '@sanity/image-url'

const builder = imageUrlBuilder({
  projectId: '8oybjcdg',
  dataset: process.env.GATSBY_SANITY_DATASET ?? 'production',
})

export function imageUrlFor(source) {
  if (source) {
    return builder.image(source)
  } else {
    return;
  }
}
