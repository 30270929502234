/* eslint-disable react/prop-types */
import React from 'react'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import { Image } from '../Image'
import * as styles from './page-css/page-intro2.module.css'
import {
  responsiveTitle4Sans,
} from '../typography.module.css'
import CountrySelector from '../preview/countrySelector'

function PageIntro(props) {
  let titleSans = styles.titleSans
  let title = styles.title
  let backgroundImage = ''

  {
    props.mainImage &&
      props.mainImage.asset &&
      (backgroundImage = imageUrlFor(buildImageObj(props.mainImage))
        .height(500)
        .fit('crop')
        .url())
  }

  const leftGrid = styles.left

  if (props.index) {
    return (
      <div
        style={{
          backgroundColor: props.backgroundColor,
          color: props.textColor,

        }}>
        <div className={styles.wrapper}>
          <div className={styles.indexIntro}>
            <div className={styles.textTitle}>
              {props.title && (
                <h1 className={responsiveTitle4Sans}>{props.title}</h1>
              )}
              {props.excerpt && (
                <h2 className={styles.mainTitle}>{props.excerpt}</h2>
              )}
            </div>
            <div>
              <CountrySelector />
            </div>

            {/* 
                <div className={styles.cta}>
                  <button className={styles.button}>Read more</button>
                </div>
            */}
          </div>
        </div>
      </div>
    )
  }

  if (props.product) {
    return (
      <div
        style={{
          backgroundColor: props.backgroundColor,
          color: props.textColor,
        }}>
        <div className={styles.wrapper}>
          <div className={styles.productIntro}>
            <div>
              {props.subtitle && (
                <h1 className={styles.subtitle}>{props.subtitle}</h1>
              )}

              {props.title && (
                <h2 className={props.sansFont ? titleSans : title}>
                  {props.title}
                </h2>
              )}
              {props.showExcerpt && props.excerpt && (
                <h2 className={styles.subtitle}>{props.excerpt}</h2>
              )}
              {props.content && (
                <p className={styles.content}>{props.content}</p>
              )}
            </div>

            <div className={styles.image}>
              {props.backgroundImage && props.backgroundImage.asset && (
                <img
                  src={imageUrlFor(buildImageObj(props.backgroundImage))
                    .width(2000)
                    .fit('fillmax')
                    .url()}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (props.solutionIntro) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.productIntro}>


          <div className={styles.textContent}>
            {props.subtitle && (
              <h3 className={styles.subtitle}>{props.subtitle}</h3>
            )}

            {props.title && (
              <h1 className={styles.title}>{props.title}</h1>
            )}
            {props.excerpt && (
              <div className={styles.excerpt}>
                <h2 className={styles.subtitle}>{props.excerpt}</h2>
              </div>
            )}
          </div>

          <div className={styles.sectionImage}>
            {props.mainImage && props.mainImage.asset && (
              <img
                src={imageUrlFor(buildImageObj(props.mainImage))
                  .width(700)
                  .height(550)
                  .url()}
              />
            )}
          </div>

        </div>
      </div>
    )
  }

  if (props.centered) {
    return (
      <div
        style={{
          backgroundColor: props.backgroundColor,
          color: props.textColor,
        }}>
        <div className={styles.wrapper}>
          <div className={styles.centered}>
            {props.title && (
              <h1 className={styles.centeredTitle}>{props.title}</h1>
            )}
            {props.excerpt && (
              <h2 className={styles.centeredSubtitle}>{props.excerpt}</h2>
            )}
          </div>
        </div>
      </div>
    )
  }
  if (props.article) {
    return (
      <div
        style={{
          backgroundColor: props.backgroundColor,
          color: props.textColor,
        }}>
        <div className={styles.wrapper}>
          <div className={styles.articleIntro}>
            
              {props.title && 
                (<h1 className={styles.pageMedTitle}>{props.title}</h1>)
            }
            {props.date &&
          <div className={styles.date}>{props.date}</div>}
          </div>
          
        </div>
      </div>
    )
  } else {
    return (
      <div
        style={{
          backgroundColor: props.backgroundColor,
          color: props.textColor,
        }}>
        <div className={styles.wrapper}>
          <div className={styles.grid}>
            <div>
              {props.title && (
                <h1 className={styles.pageTitle}>{props.title}</h1>
              )}
              {props.excerpt && (
                <div className={styles.excerpt}>
                  <h2 className={styles.subtitle}>{props.excerpt}</h2>
                </div>
              )}
            </div>
            {props.mainImage && props.mainImage.asset && (
              <div className={styles.imagePlaceholder}>
                <Image
                  mainImage={props.mainImage}
                  hasMaxWidth
                  hasRoundedEdges
                />
              </div>
            )}
            {/* <div>
              {props.mainImage && props.mainImage.asset && (
                <div
                  className={styles.imagePlaceholder}
                  style={{ backgroundImage: `url(${backgroundImage})` }} />
              )}
            </div> */}
          </div>
        </div>
      </div>
    )
  }
}

PageIntro.defaultProps = {
  mainImage: '',
  backgroundColor: '#fff',
  textColor: 'black',
  backgroundColor: 'transparent',
}

export default PageIntro
