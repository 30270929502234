// extracted by mini-css-extract-plugin
export var articleIntro = "page-intro2-module--articleIntro--5nuF2";
export var button = "page-intro2-module--button--t6EeC";
export var centered = "page-intro2-module--centered--N1gmQ";
export var centeredSubtitle = "page-intro2-module--centeredSubtitle--aP3qu typography-module--responsiveTitle4Sans--f-XZT";
export var centeredTitle = "page-intro2-module--centeredTitle--DRzAR typography-module--responsiveTitle1Medium--H5fJY";
export var content = "page-intro2-module--content--WIpbQ";
export var cta = "page-intro2-module--cta--2Vjl7";
export var date = "page-intro2-module--date--jhIMl";
export var excerpt = "page-intro2-module--excerpt--yFpAR";
export var grid = "page-intro2-module--grid--Wd+US";
export var grid2Col = "page-intro2-module--grid2Col--2ZOOC";
export var h2 = "page-intro2-module--h2--iY45f";
export var image = "page-intro2-module--image--ywBIn";
export var imagePlaceholder = "page-intro2-module--imagePlaceholder--dufUF";
export var indexIntro = "page-intro2-module--indexIntro--Q-96r";
export var left = "page-intro2-module--left--0-RFw";
export var mainTitle = "page-intro2-module--mainTitle--8mHvL";
export var pageMedTitle = "page-intro2-module--pageMedTitle--U7UwP typography-module--responsiveTitle1MediumLight--AX1e9";
export var pageTitle = "page-intro2-module--pageTitle--V3+V0 typography-module--responsiveTitle1Medium--H5fJY";
export var productIntro = "page-intro2-module--productIntro--sK8JM";
export var root = "page-intro2-module--root--kD0+B";
export var sectionImage = "page-intro2-module--sectionImage--kypfT";
export var sectionTitle = "page-intro2-module--sectionTitle--ZTAFX typography-module--responsiveTitle1Medium--H5fJY";
export var subtitle = "page-intro2-module--subtitle--57qn9 typography-module--responsiveTitle4Sans--f-XZT";
export var textContent = "page-intro2-module--textContent--2VZaM";
export var textTitle = "page-intro2-module--textTitle--F2Loj";
export var title = "page-intro2-module--title--3POVy typography-module--responsiveTitle1Medium--H5fJY";
export var titleSans = "page-intro2-module--titleSans--L-YLb typography-module--responsiveTitle1Sans--5Ge+R";
export var wrapper = "page-intro2-module--wrapper--uBLa0";