// extracted by mini-css-extract-plugin
export var base = "typography-module--base--FphVn";
export var blockQuoteGreen = "typography-module--blockQuoteGreen--ldN0s";
export var blockQuoteLarge = "typography-module--blockQuoteLarge--TVtr+";
export var blockQuoteRed = "typography-module--blockQuoteRed--qcNK6";
export var blockQuoteSymbol = "typography-module--blockQuoteSymbol--ZtdXI";
export var blockQuoteSymbolRed = "typography-module--blockQuoteSymbolRed--vQ5IS";
export var large = "typography-module--large--WR8oq";
export var micro = "typography-module--micro--ywG8y";
export var paragraph = "typography-module--paragraph--5Wxws";
export var paragraphCentered = "typography-module--paragraphCentered--9IEQR";
export var paragraphSans = "typography-module--paragraphSans--D7MAT";
export var paragraphSansBigger = "typography-module--paragraphSansBigger--b7sNS";
export var paragraphSansMedium = "typography-module--paragraphSansMedium--G18U2";
export var responsiveTitle1 = "typography-module--responsiveTitle1--0t0jA";
export var responsiveTitle1Centered = "typography-module--responsiveTitle1Centered--pEu7j";
export var responsiveTitle1Medium = "typography-module--responsiveTitle1Medium--H5fJY";
export var responsiveTitle1MediumLight = "typography-module--responsiveTitle1MediumLight--AX1e9";
export var responsiveTitle1Sans = "typography-module--responsiveTitle1Sans--5Ge+R";
export var responsiveTitle2 = "typography-module--responsiveTitle2--LNl4r";
export var responsiveTitle2Centered = "typography-module--responsiveTitle2Centered--MxYDe";
export var responsiveTitle2Reg = "typography-module--responsiveTitle2Reg--k0Wq2";
export var responsiveTitle2Sans = "typography-module--responsiveTitle2Sans--rMTb4";
export var responsiveTitle2SansCentered = "typography-module--responsiveTitle2SansCentered--5ZuFg";
export var responsiveTitle3 = "typography-module--responsiveTitle3--K+vPf";
export var responsiveTitle3Centered = "typography-module--responsiveTitle3Centered--KmX-8";
export var responsiveTitle3Sans = "typography-module--responsiveTitle3Sans--vtnbc";
export var responsiveTitle4 = "typography-module--responsiveTitle4--RYCAI";
export var responsiveTitle4Centered = "typography-module--responsiveTitle4Centered--ZDhoo";
export var responsiveTitle4Sans = "typography-module--responsiveTitle4Sans--f-XZT";
export var responsiveTitle5 = "typography-module--responsiveTitle5--m3W4A";
export var responsiveTitle5Sans = "typography-module--responsiveTitle5Sans--64BOp";
export var small = "typography-module--small--KHf9L";
export var smallTitle = "typography-module--smallTitle--Eb0ov";
export var title1 = "typography-module--title1--j5E2+";
export var title2 = "typography-module--title2--n-7kF";
export var title3 = "typography-module--title3--wIhjs";
export var title4 = "typography-module--title4--eLE6h";