import React from 'react'
import { buildImageObj } from '../lib/helpers'
import { cn } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import PropTypes from 'prop-types'
import * as styles from './Image.module.css'

export const Image = ({ mainImage, hasRoundedEdges, hasMaxWidth }) => {
  return (
    <div
      className={cn(
        styles.imageWrapper,
        hasRoundedEdges && styles.rounded,
        hasMaxWidth && styles.hasMaxWidth
      )}>
      {mainImage && mainImage.asset && (
        <img
          src={imageUrlFor(buildImageObj(mainImage))
            .fit('scale')
            .url()}
          alt={mainImage.alt}
        />
      )}
    </div>
  )
}

Image.propTypes = {
  mainImage: PropTypes.shape({
    alt: PropTypes.string,
    asset:  PropTypes.shape({
      _id: PropTypes.string,
    })
  }),
  hasRoundedEdges: PropTypes.bool,
  hasMaxWidth: PropTypes.bool,
}
